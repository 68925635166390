<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Reporte de Ventas' }]" />
    <v-card>      
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Reporte de Productos vendidos</v-toolbar-title>
        <v-spacer />
        <v-btn @click="exportFile" color="primary">
        <svg style="width:24px;height:24px; margin-right: 8px;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
        </svg>
          Exportar a Excel
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex sm3>
            <v-text-field v-model="anio" box label="Año" clearable hide-details />
          </v-flex>
          <v-flex sm3>
            <v-btn :loading="loading" :disabled="loading" color="primary" @click="getData" large>
              Generar reporte
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-flex>
          <v-flex sm12>
            <v-data-table
              :headers="[
                { text: 'Año', value: 'anio' },
                { text: 'Mes', value: 'mes' },
                { text: 'Ventas Exitosas', value: 'ventas_exitosas' },
                { text: 'Monto Total', value: 'monto_total'},
                { text: 'Carros Abandonados', value: 'abandonados' }
              ]"
              :items="datos"
              :loading="loading"
              :rows-per-page-items="[12]"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.anio }}
                </td>
                <td class="px-3">
                  {{ props.item.mes }}
                </td>
                <td class="px-3">
                  {{ props.item.ventas_exitosas }}
                </td>
								<td class="px-3">
                   ${{formatMoney(props.item.monto_total) }}
                </td>
                <td class="px-3">
                  {{ props.item.abandonados }}
                </td>
              </tr>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
import XLSX from "xlsx";

export default {
  metaInfo() {
    return { title: "Reporte Item" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/paises/ModalDeletePais")
  },
  data() {
    return {
      desde: "",
      hacia: "",
      loading: false,
      search: "",
			anio: "",
      datos: [],
      pages: "",
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    ...mapActions({
      getProductosItems: "reportes/getProductosItems"
    }),
		formatMoney(numero) {
      return new Intl.NumberFormat(["ban", "id"]).format(numero);
    },
    async getData() {
      if(this.anio == '' || this.anio === null){
        var currentTime = new Date();
        this.anio = currentTime.getFullYear();
      }else{
        this.anio = this.anio;
      }		
			this.loading = true
      let url = `${HOST}/reportes/carros/anual?anio=${this.anio}`;
      await axios.get(url).then(response => {
        this.datos = response.data.data;				
        this.loading = false;
      });
    },
    exportFile() {
      let stocks = [];
      for (let i = 0; i < this.datos.length; i++) {
        stocks.push({
          anio: this.datos[i].anio,
          mes: this.datos[i].mes,
          ventas_exitosas: this.datos[i].ventas_exitosas,
          monto_total: this.datos[i].monto_total,
          abandonados: this.datos[i].abandonados,
        });
      }
      const datos = XLSX.utils.json_to_sheet(stocks, { cellDates: true });
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, datos, "datos");
      XLSX.writeFile(wb, "Reporte_ventas.xlsx");
    },
  }
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
